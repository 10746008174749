<template>
  <main os>
    <p-section class="hero">
      <div class="title">
        <h2>FIT OS</h2>
        <p>간편한 제어</p>
      </div>
      <div class="holder" v-prx:sticky>
        <div class="kv">
          <video src="/img/os-hero.l.mp4" autoplay muted playsinline loop class="hidden-tl-down"></video>
          <video src="/img/os-hero.m.mp4" autoplay muted playsinline loop class="hidden-ml-down hidden-ds-up"></video>
          <video src="/img/os-hero.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
        </div>
        <div class="bg" v-prx:progress="{ r:[6,10], o:[0,1] }"></div>
        <div class="overview" v-prx:progress="{ r:[6,10], o:[0,1], ty:[80,0] }">
          <p v-prx:outro="{ r:[1,12], o:[1,0], ty:[0,160] }">User-Centered <br>Infrastructure</p>
        </div>
      </div>
    </p-section>
    <p-section class="feature">
      <div class="holder" v-prx:sticky>
        <ul v-prx:progress="{ r:[1,11], pan:[0,100] }">
          <li>
            <div class="inner-wrap">
              <p>CONTROL</p>
              <p>사용자 중심 <br>환경제어</p>
              <p>사용자는 간편한 방식으로 시스템에 접근할 수 있습니다. 모바일 디바이스를 활용해 공간을 제어하고 다양한 서비스를 제공받습니다.</p>
              <div class="video">
                <video src="/img/os-feature-video-1.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/os-feature-video-1.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
                <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
              </div>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <p>SOLUTION</p>
              <p>통합제어 <br>솔루션</p>
              <p>테크 카트리지, 바닥 공조 시스템, 조명 등 FIT 플랫폼의 모든 시스템은 하나의 클라우드에 연결되어 통합적으로 운영됩니다. 시스템 간 협업을 통해 효율적인 공간 운영이 가능합니다.</p>
              <div class="video">
                <video src="/img/os-feature-video-2.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/os-feature-video-2.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
                <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
              </div>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <p>UPDATE</p>
              <p>지속적인 <br>업데이트</p>
              <p>소프트웨어 업데이트를 통해 최신 기술을 지속적으로 도입합니다. 새로운 기술 도입은 간편하고 안정적으로 이루어지며, 공간의 가치는 무한히 상승합니다.</p>
              <div class="video">
                <video src="/img/os-feature-video-3.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/os-feature-video-3.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
                <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </p-section>
    <p-section class="structure">
      <div class="holder" v-prx:sticky>
        <div class="inner-wrap">
          <h3>스마트 솔루션 패키지</h3>
<!--          <video src="/img/os-solution.l.mp4" autoplay muted playsinline loop class="hidden-tp-down"></video>-->
          <img src="/img/os-solution.l.png" class="hidden-tp-down">
          <video src="/img/os-solution.s.mp4" autoplay muted playsinline loop class="hidden-tl-up"></video>
          <ol>
            <li>① ICT  카트리지</li>
            <li>② 스킨 카트리지</li>
            <li>③ 공조 카트리지</li>
            <li>④ FTU</li>
            <li>⑤ 무선 제어 조명</li>
            <li>⑥ 빌딩 통합 제어 b.IoT OS</li>
            <li>⑦ 모바일 기반 사용자 환경제어 PEM APP</li>
          </ol>
          <p>하나의 시스템을 통해 다양한 공간 솔루션을 효율적으로 통합 제어합니다. 스마트 솔루션 패키지는 사용자의 다양한 니즈에 효과적으로 대응할 수 있습니다</p>
          <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
        </div>
      </div>
    </p-section>
  </main>
</template>
<script>

export default {
  name: 'OS',
  metaInfo() {
    return {
      title: 'FIT OS',
      meta: [
        { vmid: 'title', content: 'cartridge skin', },
        { vmid: 'description', content: 'cartridge skin desc', },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/og.png`, },
        { vmid: 'path', content: this.$route.path },
      ],
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[os] {
  h3 { .fs(32,40); .tc; }
  p.astar { .fs(10,1); .c(#9C9C9C); }
  .holder { .rel; .h(100vh); .-box; .crop; }
  .hero { .rel; .h(300vh); .pt(60);
    .title { .p(80,0,48); .tc;
      h2 { .fs(16,1); color: #2e2e2e; }
      h2 + p { .fs(38,46); .bold; .mt(10); color: #1e1e1e; }
    }
    .holder { .h(100vh); .tc; }
    .kv { .abs; .lt; .f;
      video { .block; .f; object-fit: cover; }
    }
    .bg { .abs; .lt; .bgc(#000); .f; }
    .overview { .abs; .lt; .f; .flex-center;
      p { color: #fff; .fs(48,56); .bold; .mh-c; .tc; }
    }
  }
  .feature {
    .holder { .h(auto); }
    li { .p(80,0);
      p:first-child { .fs(20,1); .bold; }
      p + p { .mt(16); .fs(40,48); .bold; }
      p + p + p { .mt(40); .fs(20,32); color: #6E6E6E; }
      .video { .mt(48);
        video { .block; .wf; object-fit: contain; .br(4); .crop; }
        p { .mt(16); .tc; }
      }
    }
  }
  .structure { .p(80,0,128); .bgc(#EFEFEF);
    .holder { .h(auto); }
    video, img { .block; .mt(40); .w(304); .mh-c; object-fit: cover; }
    ol { .mt(40); .grid(2); .w(262); .mh-c; .fs(12,1); grid-row-gap: 10px;
      li { .bold; .w(140); }
      li:nth-child(odd) { .w(102); }
      li:last-of-type { white-space: nowrap; }
    }
    .holder > p { .mt(48); .fs(20,32); .bold; .w(303); .mh-c; }
    .holder > p + p { .mt(48); .fs(20,32); .bold; .w(303); .mh-c; }
    p:first-of-type { .mt(48); .fs(20,32); .bold; .w(303); .mh-c; }
    p.astar { .mt(16); .tc; }
  }
}

@media (min-width: @screen-tp-min) {
  [os] {
    .feature { .h(450vh);
      ul { .grid(3); }
      li { .rel; .w(100vw); .h(100vh); .-box; .inline-flex; flex-direction: column; .justify-center;
        p { .w(304); }
        .video { .abs; .rt(0, 50%); .t-yc; .wh(304,auto); .m;
          video { object-fit: cover; .max-h(86vh); }
        }
      }
    }
    .structure {
      video, img { .w(428); }
    }
  }
}
@media (min-width: @screen-tl-min) {
  [os] {
    h3 { .fs(40,1); .tc; }
    p.astar { .fs(14,1); }
    .hero {
      .title { .p(80, 0);
        h2 { .fs(32, 1); }
        h2 + p { .fs(64, 1); .mt(32); }
      }
      .overview p { .fs(80, 96); }
    }
    .feature {
      li {
        p { .w(392); }
        p:first-child { .fs(24,1); }
        p + p { .mt(24); .fs(56,72); }
        p + p + p { .mt(56); .fs(24,40); }
        .video { .wh(421,auto); p { .w(auto); }}
      }
    }
    .structure { .h(200vh); .p;
      .holder { .h(100vh); .flex-center; flex-direction: column; }
      video, img { .w(960); .max-w(70vh); }
      ol { .hide; }
      p:first-of-type { .mt(40); .fs(24,40); .w(726); }
    }
  }
}
@media (min-width: @screen-ds-min) {
  [os] { .rel;
    .hero { .pt(80); .h(500vh);
      .overview p { .fs(96,112); }
    }
    .feature {
      li {
        .video { .wh(632,auto); }
      }
    }
    .structure {
      video { .w(968); }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [os] {
    .hero {
      .overview p { .fs(112,128); }
    }
    .feature {
      li {
        .video { .wh(698,auto); }
      }
    }
    .structure {
      video, img { .w(1120); }
    }
  }
}
</style>
